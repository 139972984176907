import { Card, Typography } from '@material-ui/core'
import classes from './commentdetail.module.css';
import moment from 'moment';
import { Link } from 'react-router-dom'
import axios from 'axios';
import envUrls from '../../../utils/config';
import UseTime from '../../../hooks/useTime';
import { toast } from 'react-toastify';

let Commentdetail = (props) => {
    const notifyValidationsWarns = (msg) => {
        toast.clearWaitingQueue();
        toast(msg, {

            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: false,
        })
    };
    console.log(props.filename)
    let timestamp = UseTime(props.date)

    const dateFormat = timestamp
    const downloadAttachment = () => {
        // console.log("attachmentToDownload", sreceiveAttachmentLinks[attachmentToDownload])
        axios.get(envUrls.baseUrl + 'download_attachment?attachment=' + props.file).then((res) => {
            console.log(res);
            window.open(res.data.donwload_url, 'Download')
        }).catch((err) => {
            console.log(err)
            if (err.response.status == 401) {
                window.location.href = '/login'
                notifyValidationsWarns('Session expired, Please login again');
                sessionStorage.setItem('session_expired', 'true')

            }
        })
    }

    return (

        <Card elevation={8} className={classes.root} style={{ margin: '25px', backgroundColor: '#F2F2F2', borderRadius: '10px', padding: "7px 13px" }} >

            {
                props.type === 'close' ?
                    <Typography style={{ color: "#616168", fontWeight: 500, fontSize: "10px" }} variant="h6"> Closing comment</Typography> : ""
            }
            {
                props.type === 'reopen' ?
                    <Typography style={{ color: "#616168", fontWeight: 500, fontSize: "10px" }} variant="h6">Reopening comment</Typography> : ""
            }
            {
                props.type === 'Normal' ?
                    <Typography style={{ color: "#616168", fontWeight: 500, fontSize: "5px" }} variant="h6">Additional comment</Typography> : ""
            }
            <Typography variant="body2" style={{ color: "#616168" }}>By {props.author} on {dateFormat}</Typography>
            <Typography variant="subtitle2" style={{ paddingTop: "7px", color: 'black' }}>{props.comment}<br /> {props.filename?.map(val => val ? <span style={{ color: 'blue', cursor: 'pointer' }} onClick={downloadAttachment}>{val}<br /></span> : '')}
            </Typography>
        </Card >


    )
}
export default Commentdetail