// import classes from './IssueTable.module.css'
import React, { useEffect, useState } from 'react'
import MaterialTable from "material-table";
import axios from 'axios';
import envUrls from '../../utils/config';
import DialogBox from '../../components/dialog/DialogBox'
import { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
// import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Mainnavbar from '../../components/mainNavbar/Mainnavbar';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Button, Card, Checkbox, MenuItem, Select, Typography } from '@material-ui/core';
import { ClassNames } from '@emotion/react';
import Details from '../../assets/Details.png';
import windlogo from '../../assets/Windbug.png';
import classes from './issueTable.module.css'
import { NavLink, useParams } from 'react-router-dom'
import DashboardIcon from '@mui/icons-material/Dashboard';
import InfoIcon from '@mui/icons-material/Info';
import BugReportIcon from '@mui/icons-material/BugReport';
import LogoutIcon from '@mui/icons-material/Logout';

let Navbar = () => {
    function setCookie(cname, cvalue, time) {
        var date = new Date();
        date.setTime(date.getTime() + time * 60 * 1000);
        var expires = "expires=" + date.toGMTString();
        document.cookie = cname + "=" + cvalue + ";" + expires
            + ";domain=.sirpi.co.in;";
    }
    function getCookie(name) {
        var found = document.cookie.split(';').filter(c => c.trim().split("=")[0] === name);
        return found.length > 0 ? found[0].split("=")[1] : null;
    }
    const handleLogout = (e) => {
        e.preventDefault();
        axios.get(envUrls.baseUrl + 'logout').then((res) => {
            setCookie('Authorization_windbug', '', 0);
            setCookie('email_windbug', '', 0)
            // document.cookie = 'Authorization_windbug' + "=" + ''
            //     + ";domain=.sirpi.co.in;";
            // document.cookie = 'email_windbug' + "=" + ''
            //     + ";domain=.sirpi.co.in;";
            window.location.href = '/login';
        }).catch((err) => {
            console.log(err)
            setCookie('Authorization_windbug', '', 0);
            setCookie('email_windbug', '', 0)
            window.location.href = '/login';
        })

    }
    var width = window.innerWidth
    var height = window.innerHeight
    var totalpixels = width * height
    console.log(width, height, totalpixels)
    // let newid = window.location.pathname

    let newid = localStorage.getItem('latestid')
    // localStorage.removeItem()

    // console.log(p)
    return (
        <div className={classes.box1}>
            <img src={windlogo} style={{ marginBottom: '-15.1rem' }}></img>
            {/* <h4>About</h4> */}
            <div style={{ top: '0px', marginTop: '-35px' }} className={classes.nablink}>
                <NavLink to={'/issues'} className={classes.sidenavbut} activeClassName={classes.active} ><DashboardIcon sx={{ color: 'white !important', marginRight: '7px' }} /><span>Dashboard</span> </NavLink>

                {newid !== null && <NavLink to={`/issue/${newid}`} className={classes.sidenavbut} activeClassName={classes.active}><BugReportIcon sx={{ color: 'white !important', marginRight: '7px' }} /><span>Details (Case ID-{newid})</span> </NavLink>}

                <NavLink to={'/about'} className={classes.sidenavbut} activeClassName={classes.active}><InfoIcon sx={{ color: 'white !important', marginRight: '7px' }} /><span>About Us</span> </NavLink>

            </div>
            <div style={{ marginBottom: '30px', backgroundColor: '#003C8B', height: 80, width: '280px' }} className={classes.logoutbox}>
                {/* <Mainnavbar /> */}
                <Button style={{ paddingLeft: '25px', fontSize: '20px', paddingTop: '13px', textTransform: 'none' }} variant="text" onClick={handleLogout} startIcon={<LogoutIcon sx={{ color: 'white !important' }} />}>
                    <Typography style={{ color: 'white' }} >Logout</Typography>
                </Button><br />
                <span className='text-white' style={{ marginLeft: '20px', fontSize: '18px', marginTop: '-50px !important' }}>{getCookie('email_windbug')}</span><br />


            </div >
        </div >
    )
}
export default Navbar