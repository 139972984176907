import React from 'react'
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Button } from '@material-ui/core';
import classes from './exporttoexcel.module.css'
import { toast } from 'react-toastify';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileOpenIcon from '@mui/icons-material/FileOpen';
export const ExportToExcel = ({ apiData, fileName }) => {
    const notifyValidationsWarns = (msg) => {
        toast.clearWaitingQueue();
        toast(msg, {

            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: false,
        })
    };
    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const exportToCSV = (apiData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(apiData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
        notifyValidationsWarns('Exported to Excel Succesfully')
    };

    return (
        <Button variant="outlined" className={classes.butt} size={'small'} onClick={(e) => exportToCSV(apiData, fileName)}><FileDownloadIcon />Export To Excel</Button>
    );
};