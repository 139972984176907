import React from 'react';
import './maintainence.css';
import windlogo from '../../assets/Windbug.png'
// import Warning from './warning.svg';

const Maintainence = () => {
    return (
        <article>
            <img style={{ marginTop: '150px' }} src={windlogo} height={100} alt='' /><br /><br />
            <h1>Currently Under Scheduled Maintenance (6PM - 7PM) </h1><br />
            <div>
                <h1 style={{ fontSize: '25px' }}>We will be back Soon !</h1>
            </div>
        </article>
    )
}

export default Maintainence