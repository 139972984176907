import React, { useState, useEffect } from 'react'
import Navbar from '../issueTable/navbar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
const Preview = (props) => {
    console.log(props)
    const [downloadURL, setDownloadURL] = useState(localStorage.getItem('downloadURL'));
    const [open, setOpen] = React.useState(props.open);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false)
    // props.close()

    return (
        <>

            <div style={{ backgroundColor: 'white' }}>
                <Button onClick={props.close.bind(null, false)}><CloseIcon sx={{ fontSize: '30px !important', color: 'white !important', zIndex: '2000 !important', cursor: 'pointer', position: 'fixed', top: '12%', right: '26%' }} /></Button>

                <Modal
                    open={props.open}
                    onClose={() => { props.close(false) }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box style={{ border: 'solid', color: 'white', width: '700px', height: 'auto', margin: '8% auto', backgroundColor: 'white', maxHeight: '500px', overflowY: 'auto' }}>
                        <img src={downloadURL} style={{ width: '100%', height: 'auto' }} />
                    </Box>
                </Modal>
            </div>
        </>
    )
}
export default Preview