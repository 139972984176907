

const baseUrl = 'https://issue-board-backend.sirpi.co.in/';



const isMaintainence = false;
const envUrls = {
    baseUrl, isMaintainence
}



export default envUrls
