import { Card, Typography } from "@material-ui/core"
import Navbar from "../issueTable/navbar"
import classes from './issueTable.module.css'
import windlogo from '../../assets/Windbug.png';
import SirpiLOGO from '../../assets/SirpiLogo.png';
import Aboutimg from '../../assets/About.png'

let About = () => {
    return (
        <div className={classes.box}>
            <Navbar></Navbar>
            <div className={classes.box2}>
                <div className={classes.first}>
                    <h1>About us</h1>
                </div>
                <div className={classes.sec}>
                    <p>WindBug is Sirpi's in-house project management tool, which is for planning, visualizing and organizing all the bugs and requests raised by Wind Pioneers Team.</p>
                    <img src={Aboutimg}></img>
                </div>
                <div className={classes.foot}>
                    <p>
                        Developed by
                    </p>
                    <img src={SirpiLOGO}></img>
                </div>
            </div >
        </div >
    )
}
export default About
