import React, { useEffect, useState } from 'react'
import MaterialTable from "material-table";
import axios from 'axios';
import envUrls from '../../utils/config';
import DialogBox from '../../components/dialog/DialogBox'
import { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Mainnavbar from '../../components/mainNavbar/Mainnavbar';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { Link, NavLink, useHistory } from 'react-router-dom';
import moment from 'moment';
import { Card, Checkbox, MenuItem, Select } from '@material-ui/core';
import { ClassNames } from '@emotion/react';
import Details from '../../assets/Details.png';
import windlogo from '../../assets/Windbug.png';
import { Typography, DialogTitle, DialogContentText, DialogContent, Tooltip, Dialog, FormLabel, FormControlLabel, CircularProgress, RadioGroup, Radio, Box, TextField, TextareaAutosize, CardContent, CardActions, Button, FormControl, InputLabel } from '@material-ui/core'
import { toast } from 'react-toastify';

// import DialogBox from '../../components/dialog/DialogBox';
import classes from './issueTable.module.css'
import Navbar from './navbar';
import UseTime from '../../hooks/useTime';
import { ExportToExcel } from './exporttoexcel';



const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

// axios defaults
axios.defaults.withCredentials = true

function IssueTable() {
  const [rows, setRows] = useState([]);
  const [filter, setFilter] = useState(false)
  const [filterStatus, setFilterStatus] = useState('')
  const [filteredData, setFilteredData] = useState();
  const [btnDisable, setBtnDisable] = useState(false)
  const [open, setOpen] = useState(false);
  const [excel, setexcel] = useState([])
  const [title, setTitle] = useState('');
  const [level, setlevel] = useState('')
  const [instance, setInstance] = useState('Select');
  const [project, setProject] = useState('Select');
  const [priority, setPriority] = useState('');
  const [reportedon, setreportedon] = useState('')
  const [issuetype, setIssuetype] = useState('');
  const [description, setDescription] = useState('');
  const [status, setStatus] = useState('');
  const [attachment, setAttachment] = useState([]);
  const [valid, setvalid] = useState(true)
  // const [a]
  const [issuecounts, setissuecounts] = useState({ High: 0, Medium: 0, Low: 0 })


  function selectFewerProps(show) {
    const { issue_ID, issue_type, project_name, issue_title, instance, issue_stage, username, timestamp, priority, issue_status } = show;
    return { issue_ID, issue_type, project_name, issue_title, instance, issue_stage, username, timestamp, priority, issue_status };
  }
  // const newEpisodes = episodes.map(selectFewerProps);
  /*[
  {id: 1, title: "The One Where Ross Finds Out"},
  {id: 2, title: "The One With All the Resolutions"},
  {id: 3, title: "The One With The Cop"}
  ]*/
  let newfilteredData = filteredData?.map(val => selectFewerProps(val))
  console.log(newfilteredData, 'house was created')
  useEffect(() => {
    setexcel(newfilteredData)
  }, [filteredData])

  console.log(title, instance, project, priority, status)
  toast.configure();
  console.log(issuecounts)

  const notifyValidationsError = (msg) => toast(msg, {
    position: toast.POSITION.BOTTOM_CENTER,
    autoClose: false,
  });
  const notifyValidationsWarns = (msg) => {
    toast.clearWaitingQueue();
    toast(msg, {

      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: false,
    })
  };

  const notifyValidationsSuccess = (msg) => toast(msg, {
    position: toast.POSITION.BOTTOM_CENTER,
    autoClose: false,
  });

  function setCookie(cname, cvalue, time) {
    var date = new Date();
    date.setTime(date.getTime() + time * 60 * 1000);
    var expires = "expires=" + date.toGMTString();
    // document.cookie = cname + "=" + cvalue + ";" + expires + ";";
    document.cookie = cname + "=" + cvalue + ";" + expires
      + ";domain=.sirpi.co.in;";
  }

  function getCookie(name) {
    var found = document.cookie.split(';').filter(c => c.trim().split("=")[0] === name);
    return found.length > 0 ? found[0].split("=")[1] : null;
  }

  const handleLogout = (e) => {
    e.preventDefault();
    axios.get(envUrls.baseUrl + 'logout').then((res) => {

      setCookie('Authorization_windbug', '', 0);
      setCookie('email_windbug', '', 0)
      window.location.href = '/';
    }).catch((err) => {
      console.log(err)
      setCookie('Authorization_windbug', '', 0);
      setCookie('email_windbug', '', 0)
      window.location.href = '/';
    })
  }

  useEffect(() => {
    axios.get(envUrls.baseUrl + `all_issues?email=${getCookie('email_windbug')}`).then((response) => {
      let data = response.data.data
      // setexcel(response.data.data)
      let sorted = data.sort(function (a, b) {
        return b.issue_ID - a.issue_ID;
      })
      setissuecounts(response.data.count)

      console.log(response, 'ider dekh')
      // setreportedon(response.data.system_date)
      let newData = sorted.map(item => {
        const timestamp = UseTime(item.system_date)
        console.log(timestamp)
        return {
          ...item,
          timestamp: timestamp
        }
      })
      newData.map((e, index) => {
        if (e.issue_status === "In Progress" || e.issue_status === "" || e.issue_status === "Open") {
          e.issue_status = "Open"
        }
        if (e.issue_status === "Close" || e.issue_status === "Closed") {
          e.issue_status = "Closed"
        }
      })
      newData.sort(function (a, b) {
        return b.id - a.id;
      })
      setRows(newData);
      setFilteredData(newData);
      setFilterStatus('All')
      // console.log(sorted, 'this is')

    }).catch((error) => {
      console.log(error)
      if (error.response.status == 401) {
        window.location.href = '/login'
        notifyValidationsSuccess('Session expired, Please login again');
        sessionStorage.setItem('session_expired', 'true')

      }
    })
  }, [])

  useEffect(() => {
    // console.log("rows",rows)
    if (filterStatus === 'All' || filterStatus === 'Open' || filterStatus === 'Closed' || filterStatus === 'Reopen') {
      setFilteredData(filterStatus === 'All' ? rows : rows.filter(dt => dt.issue_status === filterStatus));
    }
    else {
      setFilteredData(filterStatus === 'All' ? rows : rows.filter(dt => dt.priority === filterStatus));
    }
    // console.log("setFilteredData",filteredData)

  }, [filterStatus])

  const handleChangeForIssue = (event) => {
    setTitle(event.target.value);
  };
  const handleChangeForProject = (event) => {
    setProject(event.target.value)
  }
  const handleChangeForPriority = (event) => {
    setPriority(event.target.value);
  };
  const handleChangeForIssuetype = (event) => {
    setIssuetype(event.target.value)

  }
  console.log(issuetype)
  const handeleChangeForDesc = (event) => {
    let urlEncodedDescripition = encodeURIComponent(event.target.value)
    setDescription(urlEncodedDescripition);

  };

  const handleChangeForStatus = (event) => {
    setStatus(event.target.value);
  }

  const handleChangeForInstance = (event) => {
    setInstance(event.target.value)
  }
  let fileTypeallowe = ['pdf', 'jpg', 'png', 'docx', 'jpeg', 'ppt', "txt", 'xls', 'pptx', 'xlsx', 'zip', '7z', 'csv', 'jam', 'rtf', 'tab', 'kml', 'fig']
  const handleFile = (event) => {
    const file = event.target.files;
    var fileLength = 0
    if (attachment && attachment.length > 0) {
      fileLength = attachment.length;
    }

    if (file.length > 0) {
      if (file.length + fileLength <= 4) {
        console.log("yes");
        console.log(file.length)
        if (file.length === 1) {
          for (var i = 0; i <= file.length; i++) {

            console.log(file[i].name.split('.'), 'ppppppp')
            let [filetype] = file[i].name.toLowerCase().split('.').slice(-1)
            console.log(filetype, '.....')
            if (fileTypeallowe.includes(filetype)) {

              setAttachment([...attachment, file[i]]);
            }
            else {

              notifyValidationsError('File Type not allowed')
            }
          }
        }
        else if (file.length > 1) {
          Array.from(file).forEach((element) => {
            console.log("element", element);
            attachment.push(element);
          });
          setAttachment([...attachment]);
        }
      }
      else {
        notifyValidationsError('Maximum 4 files can be uploaded')
      }
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (e) => {
    setOpen(false);
    setBtnDisable(false)
    // setBtnDisable(true)
    setTitle('')
    setProject('Select')
    setDescription('')
    setInstance('Select')
    setPriority('')
    setIssuetype('')

  };
  const handleAddIssue = (e) => {
    setBtnDisable(true)
    e.preventDefault()
    console.log(attachment)
    const fd = new FormData()
    let date = Date.now()
    fd.set("title", title)
    fd.set('status', '')
    fd.set('priority', priority)
    fd.set('project', project)
    fd.set('description', description)
    fd.set('instance', instance)
    if (attachment === null) {
      console.log("none")
      fd.set('attachment', [])
    }
    else if (attachment.length > 0 || attachment !== null) {
      for (var i = 0; i < attachment.length; i++) {
        console.log("present", attachment[i])
        fd.append('attachment', attachment[i])
      }
    }
    else {
      console.log("none")
      fd.set('attachment', [])
    }
    fd.set('email_windbug', getCookie('email_windbug'))
    const Data = {
      date,
      title,
      project,
      description,
      priority,
      status,
      fd,
      instance
    }
    // axios.get(envUrls.baseUrl)
    if (title == '' && priority == '' && project == 'Select' && issuetype == '' && description == '' && instance == 'Select') {
      notifyValidationsWarns('Please fill in the details')
      setBtnDisable(false)

    }
    else {

      axios.post(envUrls.baseUrl + `add_issue?timestamp=${Date.now()}&issue_title=${title}&description=${description}&instance=${instance}&priority=${priority}&status=${''}&issueType=${issuetype}&project_name=${project}&email=${getCookie('email_windbug')}`, fd).then((response) => {
        console.log(response)
        if (response.data.issue_id) {
          notifyValidationsSuccess('Case added successfully');
          window.location.reload()
          setOpen(false);
        }
        else {

          notifyValidationsWarns(response.data.error)
          setBtnDisable(false)
          // setTitle('');
          // setProject('');
          // setPriority('');
          // setDescription('');
          // setStatus('');
          // setAttachment([]);
          setOpen(true);
        }
        // console.log("data sent successfully")
        // 
      }).catch((err) => {
        console.log(err.message)
        if (err.response.status == 401) {
          sessionStorage.setItem('session_expired', 'true')
          window.location.href = '/login'
          // notifyValidationsSuccess('Session expired, Please login again');

        }
      })
    }
  }

  const openFileInput = () => {
    document.getElementById('fileupload').click();
  }
  let deletefileinput = (event, i) => {
    // incomplete for now
    console.log(i, attachment)
    setAttachment(attachment.filter((_, id) => id != i))
  }
  const fileName = "CaseSheet";

  return (

    <div className={classes.box}>
      <Navbar></Navbar>
      <div className={classes.box2}>
        <Card elevation={6} className={classes.dashboard}>

          <h1 className={classes.dashboardTitle}><img src={Details} style={{ marginTop: '-7px' }} /> Dashboard</h1>
          {/* <Button href='https://windbug.sirpi.co.in/issues' className={classes.backto} style={{ marginRight: '100px', height: '50px', marginTop: '20px' }}>Back to prod</Button> */}
          <div className={classes.small_box}>
            <div>
              <h3>{issuecounts.High}</h3>
              <p>High</p>

            </div>
            <div>
              <h3>{issuecounts.Medium}</h3>
              <p style={{ fontSize: '15px', fontWeight: 600, color: '#00000099' }} >Medium</p>
            </div>
            <div>
              <h3>{issuecounts.Low}</h3>
              <p style={{ fontSize: '15px', fontWeight: 600, color: '#00000099' }}>Low</p>

            </div>



          </div>

        </Card>

        <div className={classes.tab} style={{ clear: "both" }}>
          <div style={{ maxWidth: "97%", minWidth: '600px', margin: "20px auto", textAlign: 'center' }}>
            <MaterialTable style={{ textAlign: 'center', border: '1px solid lightgray', maxWidth: '76rem', }}
              icons={tableIcons}
              columns={[
                {
                  title: " Case ID", field: "issue_ID", width: "5%", headerStyle: {
                    border: "1px solid lightgray",
                    outline: "1px solid lightgray",
                    paddingRight: "0px"
                  }
                },
                {
                  title: "Case Type", field: "issue_type", width: "2%", headerStyle: {
                    border: "1px solid lightgray",
                    outline: "1px solid lightgray",
                    paddingRight: "0px"

                  }
                },
                {
                  title: "Project Name", field: "project_name", headerStyle: {
                    border: "1px solid lightgray",
                    outline: "1px solid lightgray"
                  }
                },

                {
                  title: "Case Title", field: "issue_title", headerStyle: {
                    border: "1px solid lightgray",
                    outline: "1px solid lightgray",
                    minWidth: '200px !important'
                  }, cellStyle: {
                    minWidth: '200px'
                  }
                },
                {
                  title: "Instance", field: "instance", headerStyle: {
                    border: "1px solid lightgray",
                    outline: "1px solid lightgray"
                  }
                },
                {
                  title: "Case level", field: "issue_stage", headerStyle: {
                    border: "1px solid lightgray",
                    outline: "1px solid lightgray"
                  },
                  cellStyle: {
                    width: '10px !important',
                    maxWidth: '22px ',
                  }
                },
                {
                  title: "Reported By", field: "username", headerStyle: {
                    border: "1px solid lightgray",
                    outline: "1px solid lightgray"
                  },
                  cellStyle: {
                    // width: '100px',
                    maxWidth: '10px',
                    overflow: "hidden"


                  }
                },
                {
                  title: "Reported Date", field: "timestamp",
                  render: rowData => {
                    return (
                      <span style={{ fontSize: '14px' }}>{rowData.timestamp}</span>
                    )
                  }
                  , headerStyle: {
                    border: "1px solid lightgray",
                    outline: "1px solid lightgray"
                  },
                  // cellStyle: {
                  //   minWidth: '140px'
                  // }
                },
                {
                  title: "Priority", field: "priority",
                  render: rowData => {
                    return (
                      rowData.priority === "Low" ? <p style={{ backgroundColor: "#FEC400", color: "white", fontWeight: "bold", borderRadius: '4px', width: '100%', fontSize: '14px', padding: '0px 10px', textAlign: 'center', marginBottom: '0px' }}>{rowData.priority}</p> :
                        rowData.priority === "Medium" ? <p style={{ backgroundColor: "#FF8A00", color: "white", fontWeight: "bold", borderRadius: '4px', width: '100%', fontSize: '14px', padding: '0px 10px', textAlign: 'center', marginBottom: '0px' }}>{rowData.priority}</p> :
                          <p style={{ backgroundColor: "#FE0000", color: "white", fontWeight: "bold", borderRadius: '4px', width: '100%', fontSize: '14px', padding: '0px 10px', textAlign: 'center', marginBottom: '0px' }}>{rowData.priority}</p>)
                  }
                  , headerStyle: {
                    border: "1px solid lightgray",
                    outline: "1px solid lightgray"
                  }
                },
                {
                  title: "Status", field: "issue_status",
                  render: rowData => {
                    return (
                      rowData.issue_status === "Open" ? <p style={{ backgroundColor: "green", color: "white", fontWeight: "bold", borderRadius: '4px', width: '100%', fontSize: '14px', padding: '0px 10px', textAlign: 'center', marginBottom: '0px' }}>{rowData.issue_status}</p> :
                        rowData.issue_status === "Reopen" ? <p style={{ backgroundColor: "skyblue", color: "white", fontWeight: "bold", borderRadius: '4px', width: '100%', fontSize: '14px', padding: '0px 10px', textAlign: 'center', marginBottom: '0px' }}>{rowData.issue_status}</p> :
                          <p style={{ backgroundColor: "#C4C4C4", color: "white", fontWeight: "bold", borderRadius: '4px', width: '100%', fontSize: '14px', padding: '0px 10px', textAlign: 'center', marginBottom: '0px' }}>{rowData.issue_status}</p>)
                  }
                  , headerStyle: {
                    border: "1px solid lightgray",
                    outline: "1px solid lightgray"
                  }
                },
              ]}
              data={filteredData}
              title={"All Cases"}
              actions={[

                {

                  icon: () => <Checkbox checked={filter} onChange={() => { setFilter(!filter) }} inputProps={{ 'aria-label': 'primary checkbox' }} />,
                  tooltip: 'Hide / Show Filter Option',
                  isFreeAction: true,
                  headerStyle: {
                    border: "1px solid lightgray",
                    outline: "1px solid lightgray"
                  }
                },
                {
                  icon: () =>
                    <Select style={{ border: '2px solid #C4C4C4', boxSizing: 'border-box', borderRadius: '5px', padding: '3px 5px', width: '270px', marginTop: '0px', color: '#8B8B8B', fontFamily: 'Jost', backgroundColor: 'rgba(85, 80, 80, 0.068)' }} value={filterStatus} onChange={(e) => { setFilterStatus(e.target.value) }} >
                      <MenuItem value={'All'}>All Cases</MenuItem>
                      <MenuItem value={'Open'}>Open Cases</MenuItem>
                      <MenuItem value={'Closed'}>Closed Cases</MenuItem>
                      <MenuItem value={'Reopen'}>Reopen Cases</MenuItem>
                      <MenuItem value={'High'}>High Priority</MenuItem>
                      <MenuItem value={'Medium'}>Medium Priority</MenuItem>
                      <MenuItem value={'Low'}>Low Priority</MenuItem>
                    </Select>,
                  isFreeAction: true
                },
                {
                  icon: () => {
                    return <div>
                      <button className={classes.addissuebutton} onClick={handleClickOpen}><i className="fa fa-plus"></i> &nbsp;Add Case</button>
                    </div>
                  },
                  isFreeAction: true

                },
                rowData => ({
                  name: "View",
                  icon: () => <NavLink to={`/issue/${rowData.issue_ID}`} className={classes.addiss} style={{
                    fontSize: '15px', margin: 0, textDecoration: 'none', width: '50px', borderRadius: 2, padding: 1
                  }}>View</NavLink>,
                  onClick: (rowData)
                })
              ]}
              options={{
                actionsColumnIndex: -1,
                filtering: filter,
                pageSize: 5,       // make initial page size
                pageSizeOptions: [5, 10, 20],
              }}

            />
            <ExportToExcel apiData={excel} fileName={fileName} />
          </div>
        </div>
      </div >
      <Dialog open={open} onClose={handleClose} PaperProps={{
        style: { borderRadius: '21px' }
      }} >
        <DialogTitle id="alert-dialog-title">
          <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Typography style={{ fontSize: '25px', fontWeight: '700', color: '#002960', fontFamily: 'Poppins', marginLeft: '10px' }}>
              Add Case
            </Typography>
            <Button onClick={handleClose} style={{ padding: '0px', marginRight: '-60px' }}>
              <i className='fa fa-times' style={{ color: '#ACACAC', fontWeight: '400', fontSize: '25px' }}></i>
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent style={{ marginTop: '-35px' }}>
          <DialogContentText id="alert-dialog-description">

            <Box style={{ border: 2, display: 'flex', flexDirection: 'column' }}>
              <FormControl style={{ margin: 9 }}>
                <FormControl style={{ margin: 5 }}>
                  {/* <FormLabel id="demo-row-radio-buttons-group-label" style={{ color: '#8B8B8B', fontFamily: 'Jost' }}>Issue Type</FormLabel> */}
                  <RadioGroup style={{ fontFamily: 'Jost' }}
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group" onChange={handleChangeForIssuetype}
                    value={issuetype}
                  >
                    <FormControlLabel style={{ fontFamily: 'Jost' }} value="Bug" control={<Radio color='success' style={{ color: 'red', fontFamily: 'Jost' }} />} label="Bug" />
                    <FormControlLabel style={{ fontFamily: 'Jost' }} value="Request" control={<Radio style={{ color: 'green', fontFamily: 'Jost' }} />} label="Request" />
                  </RadioGroup>
                </FormControl>

                <Select className={classes.input} label="Project" value={project} onChange={handleChangeForProject} style={{ border: '2px solid #C4C4C4', boxSizing: 'border-box', borderRadius: '6px', paddingLeft: '15px', width: '350px', marginTop: '0px', fontFamily: 'Jost' }} >
                  <MenuItem hidden selected disabled value={"Select"}>Select Project</MenuItem>
                  <MenuItem value={"Windoscope"}>Windoscope</MenuItem>
                  <MenuItem value={"Windexgraph"}>Windexgraph</MenuItem>
                  <MenuItem value={"Windcheck"}>Windcheck</MenuItem>
                  <MenuItem value={"WindcheckAdmin"}>WindcheckAdmin</MenuItem>
                  <MenuItem value={"WeeklyReports"}>WeeklyReports</MenuItem>
                  <MenuItem value={"MonthlyReports"}>MonthlyReports</MenuItem>
                  <MenuItem value={"ShearStandalone"}>ShearStandalone</MenuItem>
                  <MenuItem value={"Shear"}>Shear</MenuItem>
                  <MenuItem value={"Shear_V2"}>Shear_V2</MenuItem>
                  <MenuItem value={"LTTStandalone"}>LTTStandalone</MenuItem>
                  <MenuItem value={"LTT"}>LTT</MenuItem>
                  <MenuItem value={"WindVista"}>WindVista</MenuItem>
                  <MenuItem value={"WindVistaReports"}>WindVistaReports</MenuItem>
                  <MenuItem value={"WindBug"}>WindBug</MenuItem>
                  <MenuItem value={"KML"}>KML</MenuItem>
                </Select>
              </FormControl>
              <FormControl style={{ margin: 9 }}>
                <Select label="Instance" value={instance} onChange={handleChangeForInstance} style={{ border: '2px solid #C4C4C4', boxSizing: 'border-box', borderRadius: '6px', paddingLeft: '15px', width: '350px', marginTop: '5px', fontFamily: 'Jost' }} >
                  <MenuItem hidden selected disabled value={"Select"}>Select Instance</MenuItem>
                  <MenuItem value={"Production"}>Production</MenuItem>
                  <MenuItem value={"Beta"}>Beta</MenuItem>
                </Select>
              </FormControl>
              {/* <TextField
                label="Issue Title"
                style={{ border: '2px solid #C4C4C4', borderRadius: '6px', paddingTop:'0px'}}
                onChange={handleChangeForIssue} /> */}

              <input type="text" className='addissueinputfield' onChange={handleChangeForIssue} placeholder='Case Title' style={{ margin: 9, border: '2px solid #C4C4C4', boxSizing: 'border-box', borderRadius: '6px', paddingLeft: '15px', width: '350px', marginTop: '15px', height: '34px', fontFamily: 'Jost', }} />
              {/* <TextField
                id="outlined-multiline-flexible"
                label="Issue"
                multiline
                maxRows={4}
                style={{ margin: 9 }}
                onChange={handleChangeForIssue}

              /> */}


              <TextareaAutosize
                className='addissueinputfield'
                placeholder="Description"
                minRows={4}
                fullWidth
                maxRows={4}
                onChange={handeleChangeForDesc}
                style={{ margin: 9, border: '2px solid #C4C4C4', boxSizing: 'border-box', borderRadius: '6px', paddingLeft: '15px', width: '350px', marginTop: '20px', color: 'black', fontFamily: 'Jost' }}

              />
              {/* {description === '' && <p style={{ color: 'red' }}>Enter valid Description</p>} */}
              <FormControl style={{ margin: 9 }}>
                <FormLabel id="demo-row-radio-buttons-group-label" style={{ color: 'black', fontFamily: 'Jost' }}>Priority</FormLabel>
                <RadioGroup style={{ color: '#8B8B8B', fontFamily: 'Jost' }}
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group" onChange={handleChangeForPriority}
                  value={priority}
                >
                  <FormControlLabel style={{ color: '#8B8B8B', fontFamily: 'Jost' }} value="Low" control={<Radio style={{ color: '#8B8B8B', fontFamily: 'Jost' }} />} label="Low" />
                  <FormControlLabel style={{ color: '#8B8B8B', fontFamily: 'Jost' }} value="Medium" control={<Radio style={{ color: '#8B8B8B', fontFamily: 'Jost' }} />} label="Medium" />
                  <FormControlLabel style={{ color: '#8B8B8B', fontFamily: 'Jost' }} value="High" control={<Radio style={{ color: '#8B8B8B', fontFamily: 'Jost' }} />} label="High" />
                </RadioGroup>
              </FormControl>

              <button className='btn text-left btn-outline-dark btn-sm' onClick={openFileInput} style={{ float: 'left', width: '150px', marginLeft: 10, marginBottom: 10, borderRradius: '8px', border: '2px solid #C4C4C4', color: '#8B8B8B', fontFamily: 'Jost' }}>Choose File(s)</button>
              <div style={{ maxHeight: '50px', overflowY: 'scroll' }}>
                {

                  attachment.length > 0 && Array.from(attachment).map((elm, ind) => {
                    console.log("attachment", attachment)
                    return (
                      <div style={{ marginLeft: 10, color: '#8B8B8B', fontFamily: 'Jost' }}>
                        <span>{ind + 1}. {elm.name}  <i className='fa fa-trash text-danger' style={{ fontSize: '18px', marginLeft: '5px' }} onClick={deletefileinput.bind(null, elm, ind)} ></i></span><br />
                      </div>
                    )
                  })
                }
              </div>
              {
                // attachment.length === 0 ?
                //   <div style={{ marginLeft: 10, color: 'black', fontFamily: 'Jost' }}>
                //     <span>No File Chosen</span><br />
                //   </div> : ""
              }
              <input type="file" style={{ display: 'none' }} id="fileupload" multiple={true} onChange={handleFile} />
              <small style={{ marginLeft: '10px', marginTop: 5, color: 'black', fontFamily: 'Jost' }}>Please upload input file(s) and screenshot</small>

              <button className={classes.butt} style={{ margin: 5, padding: 5, marginTop: '20px', color: 'white', background: 'linear-gradient(97.88deg, #002960 -4.13%, #1557B0 102.47%)', borderRadius: '12px', fontSize: '1.4rem' }} variant='contained' color='success' onClick={handleAddIssue} disabled={btnDisable}>

                Submit
                {btnDisable && <CircularProgress style={{ color: "white", marginLeft: "5px" }} size={20} />
                }

              </button>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div >
  )
}

export default IssueTable
