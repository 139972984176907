import './App.css';
import { useState, useEffect } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import Login from './containers/login/Login';
import Issue from "./containers/issue/Issue"
import IssueTable from './containers/issueTable/IssueTable';
import AuthGuard from './AuthGuard';
import Maintainence from './components/maintainence/maintainence';
import envUrls from './utils/config';
import Preview from './containers/previewPage/preview';
import About from './containers/aboutpage/About';
import { toast } from 'react-toastify';

function App() {
  const notifyValidationsWarns = (msg) => {
    toast.clearWaitingQueue();
    toast(msg, {

      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: false,

    })
  };
  const [isAuth, setIsAuth] = useState(true);
  function getCookie(name) {
    var found = document.cookie.split(';').filter(c => c.trim().split("=")[0] === name);
    return found.length > 0 ? found[0].split("=")[1] : null;
  }
  useEffect(() => {
    if (getCookie('Authorization_windbug') === null || getCookie('Authorization_windbug') === '' || getCookie('Authorization_windbug') === undefined) {
      setIsAuth(false);
    }
    else {
      setIsAuth(true);
    }
  }, [isAuth])

  let session_expire = sessionStorage.getItem('session_expired')
  useEffect(() => {
    if (session_expire == 'true') {
      notifyValidationsWarns('Session expired, Please login again')
      sessionStorage.setItem('session_expired', 'false')
    }
  }, [])

  return (
    <BrowserRouter>

      {
        envUrls.isMaintainence ?
          <Switch>
            <Route path={'/maintainence'} exact component={Maintainence} />
            <Route path={'/'}><Redirect to={'/maintainence'} /></Route>
            <Route path={'*'}><Redirect to={'/maintainence'} /></Route>
          </Switch>
          :
          <Switch>
            <AuthGuard path={'/about'} component={About} isAuth={isAuth} />
            <AuthGuard path={'/issues'} component={IssueTable} isAuth={isAuth} />
            <AuthGuard path={'/issue/:id'} component={Issue} isAuth={isAuth} />
            <Route path={'/login'} exact component={Login} />
            {
              isAuth ? <Route path={'/'}><Redirect to={'/issues'} /></Route> : <Route path={'/'}><Redirect to={'/login'} /></Route>
            }
          </Switch>

      }

    </BrowserRouter>
  );
}

export default App;
