import React from 'react'
import { Route, Redirect } from 'react-router-dom'

function AuthGuard({ isAuth, component: Component, ...rest }) {
    return <Route {...rest} render={(props) => {
        if (isAuth) {
            return <Component {...props}/>
        }
        else {
            return (<Redirect to={{ pathname: '/login', state: { from: props.location } }} />);
        }
    }} />;

}

export default AuthGuard