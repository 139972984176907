import React, { useState, useEffect } from 'react'
import { Card, Typography } from "@material-ui/core";
import Commentdetail from "./commentdetail"
import Comments from './comments';
import classes from './commentdetail.module.css'
import axios from 'axios';
import envUrls from '../../../utils/config';
import { toast } from 'react-toastify';


const Commented = (props) => {
    // let dummyarray = [{ comment: 'This is the comment of new bar dfgsjdf jhfgsh ngdfsg dsgsd', author: 'Surpreet', type: 'close' }, { comment: 'This is the comment to the new assigne', author: 'Jai', type: 'reopen' }]
    const [array, setArray] = useState([]);
    const [filename, setfile] = useState([])
    const [msg, setmsg] = useState('')
    const notifyValidationsWarns = (msg) => {
        toast.clearWaitingQueue();
        toast(msg, {

            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: false,
        })
    };

    useEffect(() => {
        axios.get(envUrls.baseUrl + 'comment?issue_id=' + props.id).then((res) => {
            console.log("comments", res)
            if (res.data.message) {
                setArray([])
                setmsg(res.data.message)
            }
            else {
                setArray(res.data)
                setfile(res.data[0].file_names)

            }

        }).catch((error) => {
            console.log(error)
            if (error.response.status == 401) {
                window.location.href = '/login'
                notifyValidationsWarns('Session expired, Please login again');
                sessionStorage.setItem('session_expired', 'true')

            }
        })
    }, [])
    console.log(filename)
    console.log(array, 'yeh hai array', filename)
    return (
        <>

            <Typography variant="h6" style={{ marginLeft: "10px" }}>Comments</Typography>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Card elevation={4} className={classes.root} style={{ margin: '10px', height: 430, overflow: 'auto', padding: 10 }}>
                    {/* defining the comment */}
                    <div>
                        {array.length == 0 ? <h4 style={{ margin: 150, textAlign: 'center', fontSize: '1.4rem', color: '#616168' }}>{msg}</h4> :
                            array?.map(val => <Commentdetail comment={val.comment_msg} author={val.username} date={val.system_date} type={val.type} filename={val.file_names} file={val.attachment}  ></Commentdetail>)}
                    </div>
                </Card>
                <div>
                    <Comments issueStatus={props.issueStatus} performActionStatus={props.performActionStatus} issuerEmail={props.issuerEmail} id={props.id} email={props.email} file={filename} fileType={props.issueType}></Comments>
                </div>
            </div>
        </>
    )
}
export default Commented