import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios'
import envUrls from '../../utils/config'
import { useParams } from "react-router-dom"
import { Typography, DialogTitle, DialogContentText, DialogContent, Tooltip, Dialog, FormLabel, FormControlLabel, RadioGroup, Radio, Box, TextField, TextareaAutosize, CardContent, CardActions, Card, Button, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@material-ui/core'
import Comments from './comments/comments';
import FileBase from 'react-file-base64';
import '../../components/dialog/DialogBox.css';
import Mainnavbar from '../../components/mainNavbar/Mainnavbar';
import './Issue.css';
import { toast } from 'react-toastify';
import Navbar from '../issueTable/navbar';
import './issueTable.module.css'
import classe from './issueTable.module.css'
import Commented from './comments/commented';
// import useTime from '../../hooks/usetime';
import UseTime from '../../hooks/useTime';
import Preview from '../previewPage/preview';
// import InputLabel from '@mui/material/InputLabel';
// or
// import { InputLabel } from '@mui/material';




const useStyles = makeStyles({
    root: {
        minWidth: 275,
        margin: 30

    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

const Issue = () => {
    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [project, setProject] = useState('');
    const [priority, setPriority] = useState('');
    const [description, setDescription] = useState('');
    const [status, setStatus] = useState('');
    const [editattachment, setEditAttachment] = useState([]);
    const [instance, setinstance] = useState('')
    const [report, setreport] = useState('')
    const [level, setlevel] = useState('')
    const [issueType, setissuetype] = useState('')
    const [reportedon, setreportedon] = useState('')
    const [showpreviewmodal, setshowpreviewmodal] = useState(false)
    const [leveldisable, setleveldisable] = useState(false)
    // const [previewmodal, setpreviewmodal] = useState(true)

    const classes = useStyles();
    const [issue, setIssue] = useState({})
    const [attachment, setAttachment] = useState(false)
    const [attachmentdata, setAttachmentdata] = useState([])
    const { id } = useParams()
    const [issueStatus, setIssueStatus] = useState('');
    const [performActionStatus, setPerformActionStatus] = useState('');
    const [receiveAttachment, setReceiveAttachment] = useState([]);
    const [receiveAttachmentLinks, setReceiveAttachmentLinks] = useState([]);
    const [attachmentToDownload, setAttachmentToDownload] = useState('in_progress');
    // axios defaults
    axios.defaults.withCredentials = true
    toast.configure();
    let param = useParams()

    localStorage.setItem('latestid', param.id)
    const notifyValidationsError = (msg) => toast.error(msg, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: false,
    });

    const notifyValidationsSuccess = (msg) => toast(msg, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: false,
    });
    console.log(receiveAttachment)
    function getCookie(name) {
        var found = document.cookie.split(';').filter(c => c.trim().split("=")[0] === name);
        return found.length > 0 ? found[0].split("=")[1] : null;
    }

    const showEachIssue = () => {

        console.log("called")
        axios.get(envUrls.baseUrl + `show_issue?issue_id=${id}&email=${getCookie('email_windbug')}`).then((response) => {


            console.log(response.data, "%c issue hai yeh", 'color:blue')
            setProject(response.data.project_name);
            setTitle(response.data.issue_title);
            setinstance(response.data.instance)
            setreport(response.data.username)
            setissuetype(response.data.issue_type)
            setDescription(response.data.description)
            setPriority(response.data.priority);
            setreportedon(response.data.system_date)
            setlevel(response.data.issue_stage)

            // setEditAttachment(response.data.attachment);
            if (response.data.file_names.length > 0 && response.data.file_names[0] !== '') {
                setReceiveAttachment(response.data.file_names)
                setReceiveAttachmentLinks(response.data.attachment)
                // console.log(response.data.file_names[0].split("', '"));
            }
            const date = response.data.system_date
            const timestamp = new Date(+date).toDateString()
            if (response.data.issue_status === '' || response.data.issue_status === null || response.data.issue_status === 'Open') {
                setIssueStatus('Open');
                setPerformActionStatus('Close');
            }
            else if (response.data.issue_status === 'Closed' || response.data.issue_status === 'Close') {
                setIssueStatus('Closed');
                setPerformActionStatus('Reopen');
            }
            else if (response.data.issue_status === 'Reopen') {
                setIssueStatus('Reopen');
                setPerformActionStatus('Close');
            }
            // console.log(timestamp)
            const newData = {
                ...response.data,
                timestamp
            }
            if (newData.attachment !== "NULL") {
                setAttachment(() => !attachment)
            }
            setIssue(newData)
        }).catch((err) => {
            console.log(err.message)
            if (err.response.status == 401) {
                window.location.href = '/login'
                notifyValidationsSuccess('Session expired, Please login again');
                sessionStorage.setItem('session_expired', 'true')

            }
        })
    }

    useEffect(() => {
        showEachIssue()
    }, [])
    console.log(receiveAttachmentLinks, 'receiveAttachmentLinks')
    const changeStatus = () => {
        const fd = new FormData()
        fd.set("issue_id", id)
        fd.set("email_windbug", getCookie('email_windbug'))
        fd.set('issue_status', performActionStatus)
        axios.post(envUrls.baseUrl + `update_issue`, fd).then((response) => {

            console.log(response)
            if (response.data.message === 'Updation successfull') {
                setOpen(false);
                showEachIssue();
                if (performActionStatus === 'Close') {
                    notifyValidationsSuccess('Case has been successfully ' + performActionStatus.toLowerCase() + 'd');
                }
                else {
                    notifyValidationsSuccess('Case has been successfully ' + performActionStatus.toLowerCase() + 'ed');
                }
            }
        }).catch((err) => {
            console.log(err.message)
            if (err.response.status == 401) {
                window.location.href = '/login'
                notifyValidationsSuccess('Session expired, Please login again');
                sessionStorage.setItem('session_expired', 'true')

            }
        })
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (e) => {
        setOpen(false);
    };

    const handleChangeForIssue = (event) => {
        setTitle(event.target.value);
    };
    const handleChangeForProject = (event) => {
        setProject(event.target.value)
    }
    const handleChangeForPriority = (event) => {
        setPriority(event.target.value);
    };
    const handeleChangeForDesc = (event) => {
        setDescription(event.target.value);
    };

    const handleChangeForStatus = (event) => {
        setStatus(event.target.value)

    }

    const handleFile = (event) => {
        const file = event.target.files;
        var fileLength = 0
        var receivedFileLength = 0
        if (editattachment && editattachment.length > 0) {
            fileLength = editattachment.length;
        }

        if (receiveAttachment && receiveAttachment.length > 0) {
            fileLength = receiveAttachment.length;
        }

        if (file.length > 0) {
            if (file.length + fileLength + receivedFileLength <= 4) {
                console.log("yes");
                console.log(file.length)
                if (file.length === 1) {
                    for (var i = 0; i <= file.length; i++) {
                        console.log(file[i].name)
                        setEditAttachment([...editattachment, file[i]]);
                    }
                }
                else if (file.length > 1) {
                    Array.from(file).forEach((element) => {
                        console.log("element", element);
                        editattachment.push(element);
                    });
                    setEditAttachment([...editattachment]);
                }
            }
            else {
                notifyValidationsSuccess('Maximum 4 files can be uploaded')
            }
        }

    }
    // var monthsArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
    // let timestamp = reportedon
    // timestamp = timestamp.slice(0, -3)

    // const date = new Date(timestamp * 1000)
    // const month = monthsArr[date.getMonth()]
    // const day = date.getDate()
    // const year = date.getFullYear()
    // const time = date.toLocaleTimeString()

    // const dateFormat = `${month} ${day}, ${year}, ${time}`
    let dateFormat = UseTime(reportedon)

    const handleEditIssue = (e) => {
        e.preventDefault()
        console.log(title, description, priority, id, status, project)
        // console.log(editattachment)
        const fd = new FormData()
        fd.set("issue_id", id)
        fd.set("timestamp", Date.now())
        fd.set("issue_title", title)
        fd.set('status', status)
        fd.set('priority', priority)
        fd.set('project_name', project)
        fd.set('description', description)
        fd.set('email', getCookie('email_windbug'))
        fd.set('issue_status', issueStatus)
        // fd.set('attachment', editattachment)
        if (editattachment === null) {
            console.log("none")
            fd.set('attachment', [])
        }
        else if (editattachment.length > 0 || editattachment !== null) {
            for (var i = 0; i < editattachment.length; i++) {
                console.log("present", editattachment[i])
                fd.append('attachment', editattachment[i])
            }
        }
        else {
            console.log("none")
            fd.set('attachment', [])
        }
        axios.post(envUrls.baseUrl + `update_issue`, fd).then((response) => {

            console.log(response)

            if (response.data.message === 'Updation successfull') {
                setOpen(false);
                showEachIssue();
                window.location.reload()
            }

        }).catch((err) => {
            console.log(err.message)
            if (err.response.status == 401) {
                window.location.href = '/login'
                notifyValidationsSuccess('Session expired, Please login again');
                sessionStorage.setItem('session_expired', 'true')

            }

        })
    }

    const previewAttachment = () => {
        console.log("attachmentToDownload", receiveAttachmentLinks[attachmentToDownload])
        var fileTypeSplit = receiveAttachment[attachmentToDownload].split('.');
        var length = fileTypeSplit.length;
        var fileType = fileTypeSplit[length - 1];
        var allowedFileTypes = ['jpg', 'png', 'jpeg', 'JPG', 'JPEG', 'PNG'];
        if (allowedFileTypes.includes(fileType)) {
            axios.get(envUrls.baseUrl + 'download_attachment?attachment=' + receiveAttachmentLinks[attachmentToDownload]).then((res) => {
                console.log(res);
                localStorage.setItem("downloadURL", res.data.donwload_url);
                // window.location.href = '/preview';
                // window.open(res.data.donwload_url)
                setshowpreviewmodal(true)
            }).catch((err) => {
                console.log(err)
                if (err.response.status == 401) {
                    window.location.href = '/login'
                    notifyValidationsSuccess('Session expired, Please login again');
                    sessionStorage.setItem('session_expired', 'true')

                }
            })
        }
        else {
            notifyValidationsSuccess("Only image files can be previewed");
        }
        // console.log(fileName)

    }

    const downloadAttachment = () => {
        console.log("attachmentToDownload", attachmentToDownload == 'in_progress')
        if (attachmentToDownload !== 'in_progress') {

            axios.get(envUrls.baseUrl + 'download_attachment?attachment=' + encodeURIComponent(receiveAttachmentLinks[attachmentToDownload == 'in_progress' ? '' : attachmentToDownload])).then((res) => {
                if (res.status == 401) {
                    window.location.href = '/login'
                    // useHistory()
                }
                console.log(res);
                window.open(res.data.donwload_url, 'Download')
            }).catch((err) => {
                console.log(err)
                if (err.response.status == 401) {
                    window.location.href = '/login'
                    notifyValidationsSuccess('Session expired, Please login again');
                    sessionStorage.setItem('session_expired', true)
                }
            })
        }

    }

    // const openFileInput = () => {
    //     document.getElementById('fileupload').click();
    // }

    console.log(issue)



    const [age, setAge] = React.useState('');
    const handleChange = (event) => {
        setAge(event.target.value);
        const fd = new FormData()
        fd.set("issue_id", id)
        fd.set('issue_stage', event.target.value)
        axios.post(envUrls.baseUrl + 'update_issue', fd).then(res => {
            if (res.data.message) {
                notifyValidationsSuccess(res.data.message)
                setleveldisable(true)
                setAge(level)
                // alert(leveldisable)
            }
            console.log(res)
        })
    };
    return (
        <div className={classe.box}>
            <Navbar></Navbar>
            <div className={classe.box2}>
                <div className={classe.issuebox}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>

                            <p style={{ fontSize: '1.4rem', marginLeft: '1.4rem' }} >{issueType}</p>
                        </div>
                        <div >
                            <Box sx={{ minWidth: 120 }} >

                                <FormControl sx={{ m: 1, minWidth: 120 }}>

                                    <Select
                                        value={age}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        style={{ minWidth: '200px', textAlign: 'center' }}
                                        disabled={leveldisable}
                                    >
                                        <MenuItem disabled={leveldisable} value="">
                                            {level == null ? <label>Assign the Case Level</label> : <em>{level}</em>}
                                        </MenuItem>

                                        <MenuItem value={'Stage 1'}>Stage1</MenuItem>
                                        <MenuItem value={'Stage 2'}>Stage2</MenuItem>
                                        <MenuItem value={'Stage 3'}>Stage3</MenuItem>
                                        <MenuItem value={'Stage 4'}>Stage4</MenuItem>
                                        <MenuItem value={'Stage 5'}>Stage5</MenuItem>
                                        <MenuItem value={'Stage 6'}>Stage6</MenuItem>
                                        <MenuItem value={'Stage 7 (Closed)'}>Stage7 (Closed)</MenuItem>

                                    </Select>

                                </FormControl>

                            </Box>
                        </div>

                    </div>
                    {/* start boxes for input */}
                    <Card elevation={3} className={classe.inputbox}>

                        <div className={classe.inputbox1}>
                            <div>
                                <label htmlFor="inputFirstName" className="form-label">Case ID:</label>
                                <br />
                                <input className='input-lineBy' type="textbox" readOnly value={id} />
                            </div>
                            <div>
                                <label htmlFor="inputFirstName" className="form-label" >Case Priority:</label>
                                <br />
                                <input className='input-lineBy' type="textbox" readOnly value={priority} />
                            </div>

                        </div>
                        <div className={classe.inputbox2} >
                            <div >
                                <label htmlFor="inputFirstName" className="form-label">Case Instance:</label>
                                <br />
                                <input className='input-lineBy' type="textbox" readOnly value={instance} />
                            </div>
                            <div>
                                <label htmlFor="inputFirstName" className="form-label">Reported By:</label>
                                <br />

                                <input className='input-lineBy' type="textbox" readOnly value={report} />
                            </div>

                        </div>
                        <div className={classe.inputbox3} >
                            <div >
                                <label htmlFor="inputFirstName" className="form-label">Case Title:</label>
                                <br />
                                <input className='input-lineBy' type="textbox" readOnly value={title} />
                            </div>
                            <div>
                                <label htmlFor="inputFirstName" className="form-label">Date:</label>
                                <br />

                                <input className='input-lineBy' type="textbox" readOnly value={dateFormat} />
                            </div>

                        </div>
                        <div className={classe.descriptionbox} >
                            <label htmlFor="inputFirstName" className="form-label">Case Description:</label>
                            <br />
                            <textarea style={{ borderRadius: '5px', width: '29rem' }} name="" id="" cols="45" rows="8" readOnly value={description} className='input-lineBy'></textarea>
                        </div>

                        {/* this start for files uploaded */}
                        <div className={classe.file}>
                            <label htmlFor="inputFirstName" className="form-label">File(s) Uploaded:              </label>
                            <select value={attachmentToDownload} onChange={(e) => { setAttachmentToDownload(e.target.value) }}
                                class="bootstrap-select  font-weight-medium"
                                style={{ cursor: "pointer", width: "200px", height: "37px", backgroundColor: "#C4C4C4", marginLeft: "15px", borderRadius: "5px" }}

                            >
                                {receiveAttachment.length == 0 ? '' : <option value="in_progress" className="select">
                                    Select file from here
                                </option>}
                                {receiveAttachment.length > 0 ? receiveAttachment.map((val, ind) => {
                                    return (
                                        <option value={ind} className="select">
                                            {val}
                                        </option>)
                                }) : <option value="in_progress" className="select">
                                    No file attached
                                </option>}

                            </select>

                        </div>
                        <div className={classe.filebutt} >
                            <button onClick={previewAttachment}>Preview</button>
                            <button onClick={downloadAttachment}>Download</button>
                        </div>
                    </Card>
                </div>
                <div style={{ border: 'solid 1px lightgrey', height: '300px', marginTop: '230px', marginRight: '15px' }}></div>

                {/* another box  */}
                <div className={classe.commentbox}>

                    <Commented id={id} email={getCookie('email_windbug')} performActionStatus={performActionStatus} issueStatus={issueStatus} issueType={issueType} ></Commented>

                </div>
            </div>
            {showpreviewmodal && <Preview open={showpreviewmodal} close={setshowpreviewmodal} />}
        </div >

    );
}


export default Issue
