let UseTime = (dated) => {

    // var monthsArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
    let timestamp = dated.slice(0, -3)
    const date = new Date(timestamp * 1000)
    const month = date.getMonth() + 1
    const day = date.getDate()
    const year = date.getFullYear().toString().substr(-2)
    function formatAMPM(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    }

    let time = formatAMPM(date);


    const dateFormat = `${day}/${month}/${year},${time}`

    return dateFormat
}
export default UseTime